/* eslint-disable */
const packages: Array<object> = [
  {
    "id": "CloudLinux Releases",
    "name": "CloudLinux Releases",
    "children": [
      {
        "id": "cloudlinux-release-6",
        "name": "CloudLinux 6",
        "route": "cloudlinux-release-6",
        "versions": {}
      },
      {
        "id": "cloudlinux-release-7",
        "name": "CloudLinux 7",
        "route": "cloudlinux-release-7",
        "versions": {}
      },
      {
        "id": "cloudlinux-release-8",
        "name": "CloudLinux 8",
        "route": "cloudlinux-release-8",
        "versions": {}
      },
      {
        "id": "cloudlinux-release-9",
        "name": "CloudLinux 9",
        "route": "cloudlinux-release-9",
        "versions": {}
      }
    ]
  },
  {
    "id": "CloudLinux Kernels",
    "name": "CloudLinux Kernels",
    "children": [
      {
        "id": "cloudlinux6",
        "name": "CloudLinux 6",
        "route": "cloudlinux6",
        "versions": {}
      },
      {
        "id": "cloudlinux6h",
        "name": "CloudLinux 6 hybrid",
        "route": "cloudlinux6h",
        "versions": {}
      },
      {
        "id": "cloudlinux7",
        "name": "CloudLinux 7",
        "route": "cloudlinux7",
        "versions": {}
      },
      {
        "id": "cloudlinux7h",
        "name": "CloudLinux 7 hybrid",
        "route": "cloudlinux7h",
        "versions": {}
      },
      {
        "id": "cloudlinux8",
        "name": "CloudLinux 8",
        "route": "cloudlinux8",
        "versions": {}
      },
      {
        "id": "cloudlinux9",
        "name": "CloudLinux 9",
        "route": "cloudlinux9",
        "versions": {}
      }
    ]
  },
  {
    "id": "LVE Components",
    "name": "LVE Components",
    "children": [
      {
        "id": "lve",
        "name": "LVE Package",
        "route": "lve",
        "versions": {}
      },
      {
        "id": "lvemanager",
        "name": "CloudLinux Manager",
        "route": "lvemanager",
        "versions": {}
      },
      {
        "id": "lve-stats",
        "name": "LVE Stats",
        "route": "lve-stats",
        "versions": {}
      },
      {
        "id": "lve-utils",
        "name": "LVE Utils",
        "route": "lve-utils",
        "versions": {}
      },
      {
        "id": "lve-wrappers",
        "name": "LVE Wrappers",
        "route": "lve-wrappers",
        "versions": {}
      },
      {
        "id": "kmod-lve",
        "name": "kmod-lve",
        "route": "kmod-lve",
        "versions": {}
      },
      {
        "id": "pam_lve",
        "name": "pam_lve",
        "route": "pam_lve",
        "versions": {}
      }
    ]
  },
  {"id": "CageFS", "name": "CageFS", "route": "cagefs", "versions": {}},
  {"id": "apache2nginx", "name": "apache2nginx", "route": "apache2nginx", "versions": {}},
  {
    "id": "alt-PHP Versions",
    "name": "alt-PHP Versions",
    "children": [
      {
        "id": "alt-php44",
        "name": "alt-php 4",
        "route": "alt-php44",
        "versions": {}
      },
      {
        "id": "alt-php5",
        "name": "alt-php 5",
        "children": [
          {
            "id": "alt-php51",
            "name": "alt-php 5.1",
            "route": "alt-php51",
            "versions": {}
          },
          {
            "id": "alt-php52",
            "name": "alt-php 5.2",
            "route": "alt-php52",
            "versions": {}
          },
          {
            "id": "alt-php53",
            "name": "alt-php 5.3",
            "route": "alt-php53",
            "versions": {}
          },
          {
            "id": "alt-php54",
            "name": "alt-php 5.4",
            "route": "alt-php54",
            "versions": {}
          },
          {
            "id": "alt-php55",
            "name": "alt-php 5.5",
            "route": "alt-php55",
            "versions": {}
          },
          {
            "id": "alt-php56",
            "name": "alt-php 5.6",
            "route": "alt-php56",
            "versions": {}
          }
        ]
      },
      {
        "id": "alt-php7",
        "name": "alt-php 7",
        "children": [
          {
            "id": "alt-php70",
            "name": "alt-php 7.0",
            "route": "alt-php70",
            "versions": {}
          },
          {
            "id": "alt-php71",
            "name": "alt-php 7.1",
            "route": "alt-php71",
            "versions": {}
          },
          {
            "id": "alt-php72",
            "name": "alt-php 7.2",
            "route": "alt-php72",
            "versions": {}
          },
          {
            "id": "alt-php73",
            "name": "alt-php 7.3",
            "route": "alt-php73",
            "versions": {}
          },
          {
            "id": "alt-php74",
            "name": "alt-php 7.4",
            "route": "alt-php74",
            "versions": {}
          }
        ]
      },
      {
        "id": "alt-php8",
        "name": "alt-php 8",
        "children": [
          {
            "id": "alt-php80",
            "name": "alt-php 8.0",
            "route": "alt-php80",
            "versions": {}
          },
          {
            "id": "alt-php81",
            "name": "alt-php 8.1",
            "route": "alt-php81",
            "versions": {}
          },
          {
            "id": "alt-php82",
            "name": "alt-php 8.2",
            "route": "alt-php82",
            "versions": {}
          },
          {
            "id": "alt-php83",
            "name": "alt-php 8.3",
            "route": "alt-php83",
            "versions": {}
          },
          {
            "id": "alt-php84",
            "name": "alt-php 8.4",
            "route": "alt-php84",
            "versions": {}
          }
        ]
      },
      {
        "id": "alt-ImageMagick",
        "name": "alt-ImageMagick",
        "route": "alt-ImageMagick",
        "versions": {}
      },
      {
        "id": "alt-php-pecl-ext",
        "name": "alt-php-pecl-ext",
        "children": [
          {
            "id": "alt-php51-pecl-ext",
            "name": "alt-php51-pecl-ext",
            "route": "alt-php51-pecl-ext",
            "versions": {}
          },
          {
            "id": "alt-php52-pecl-ext",
            "name": "alt-php52-pecl-ext",
            "route": "alt-php52-pecl-ext",
            "versions": {}
          },
          {
            "id": "alt-php53-pecl-ext",
            "name": "alt-php53-pecl-ext",
            "route": "alt-php53-pecl-ext",
            "versions": {}
          },
          {
            "id": "alt-php54-pecl-ext",
            "name": "alt-php54-pecl-ext",
            "route": "alt-php54-pecl-ext",
            "versions": {}
          },
          {
            "id": "alt-php55-pecl-ext",
            "name": "alt-php55-pecl-ext",
            "route": "alt-php55-pecl-ext",
            "versions": {}
          },
          {
            "id": "alt-php56-pecl-ext",
            "name": "alt-php56-pecl-ext",
            "route": "alt-php56-pecl-ext",
            "versions": {}
          },
          {
            "id": "alt-php70-pecl-ext",
            "name": "alt-php70-pecl-ext",
            "route": "alt-php70-pecl-ext",
            "versions": {}
          },
          {
            "id": "alt-php71-pecl-ext",
            "name": "alt-php71-pecl-ext",
            "route": "alt-php71-pecl-ext",
            "versions": {}
          },
          {
            "id": "alt-php72-pecl-ext",
            "name": "alt-php72-pecl-ext",
            "route": "alt-php72-pecl-ext",
            "versions": {}
          },
          {
            "id": "alt-php73-pecl-ext",
            "name": "alt-php73-pecl-ext",
            "route": "alt-php73-pecl-ext",
            "versions": {}
          },
          {
            "id": "alt-php74-pecl-ext",
            "name": "alt-php74-pecl-ext",
            "route": "alt-php74-pecl-ext",
            "versions": {}
          },
          {
            "id": "alt-php80-pecl-ext",
            "name": "alt-php80-pecl-ext",
            "route": "alt-php80-pecl-ext",
            "versions": {}
          },
          {
            "id": "alt-php81-pecl-ext",
            "name": "alt-php81-pecl-ext",
            "route": "alt-php81-pecl-ext",
            "versions": {}
          },
          {
            "id": "alt-php82-pecl-ext",
            "name": "alt-php82-pecl-ext",
            "route": "alt-php82-pecl-ext",
            "versions": {}
          },
          {
            "id": "alt-php83-pecl-ext",
            "name": "alt-php83-pecl-ext",
            "route": "alt-php83-pecl-ext",
            "versions": {}
          }
        ]
      },
      {
        "id": "alt-php-config",
        "name": "alt-php-config",
        "route": "alt-php-config",
        "versions": {}
      }
    ]
  },
  {
    "id": "alt-python Versions",
    "name": "alt-python Versions",
    "children": [
      {
        "id": "alt-python27",
        "name": "alt-python 2.7",
        "route": "alt-python27",
        "versions": {}
      },
      {
        "id": "alt-python34",
        "name": "alt-python 3.4",
        "route": "alt-python34",
        "versions": {}
      },
      {
        "id": "alt-python35",
        "name": "alt-python 3.5",
        "route": "alt-python35",
        "versions": {}
      },
      {
        "id": "alt-python36",
        "name": "alt-python 3.6",
        "route": "alt-python36",
        "versions": {}
      },
      {
        "id": "alt-python37",
        "name": "alt-python 3.7",
        "route": "alt-python37",
        "versions": {}
      },
      {
        "id": "alt-python38",
        "name": "alt-python 3.8",
        "route": "alt-python38",
        "versions": {}
      },
      {
        "id": "alt-python39",
        "name": "alt-python 3.9",
        "route": "alt-python39",
        "versions": {}
      },
      {
        "id": "alt-python310",
        "name": "alt-python 3.10",
        "route": "alt-python310",
        "versions": {}
      },
      {
        "id": "alt-python311",
        "name": "alt-python 3.11",
        "route": "alt-python311",
        "versions": {}
      },
      {
        "id": "alt-python312",
        "name": "alt-python 3.12",
        "route": "alt-python312",
        "versions": {}
      },
      {
        "id": "alt-python27-cllib",
        "name": "alt-python27-cllib",
        "route": "alt-python27-cllib",
        "versions": {}
      },
      {
        "id": "alt-python-virtualenv",
        "name": "alt-python-virtualenv",
        "route": "alt-python-virtualenv",
        "versions": {}
      }
    ]
  },
  {
    "id": "alt-nodejs Versions",
    "name": "alt-nodejs Versions",
    "children": [
      {
        "id": "alt-nodejs-6",
        "name": "alt-nodejs 6",
        "route": "alt-nodejs-6",
        "versions": {}
      },
      {
        "id": "alt-nodejs-8",
        "name": "alt-nodejs 8",
        "route": "alt-nodejs-8",
        "versions": {}
      },
      {
        "id": "alt-nodejs-9",
        "name": "alt-nodejs 9",
        "route": "alt-nodejs-9",
        "versions": {}
      },
      {
        "id": "alt-nodejs-10",
        "name": "alt-nodejs 10",
        "route": "alt-nodejs-10",
        "versions": {}
      },
      {
        "id": "alt-nodejs-11",
        "name": "alt-nodejs 11",
        "route": "alt-nodejs-11",
        "versions": {}
      },
      {
        "id": "alt-nodejs-12",
        "name": "alt-nodejs 12",
        "route": "alt-nodejs-12",
        "versions": {}
      },
      {
        "id": "alt-nodejs-14",
        "name": "alt-nodejs 14",
        "route": "alt-nodejs-14",
        "versions": {}
      },
      {
        "id": "alt-nodejs-16",
        "name": "alt-nodejs 16",
        "route": "alt-nodejs-16",
        "versions": {}
      },
      {
        "id": "alt-nodejs-18",
        "name": "alt-nodejs 18",
        "route": "alt-nodejs-18",
        "versions": {}
      },
      {
        "id": "alt-nodejs-19",
        "name": "alt-nodejs 19",
        "route": "alt-nodejs-19",
        "versions": {}
      },
      {
        "id": "alt-nodejs-20",
        "name": "alt-nodejs 20",
        "route": "alt-nodejs-20",
        "versions": {}
      },
      {
        "id": "alt-nodejs-22",
        "name": "alt-nodejs 22",
        "route": "alt-nodejs-22",
        "versions": {}
      }
    ]
  },
  {
    "id": "alt-ruby Versions",
    "name": "alt-ruby Versions",
    "children": [
      {
        "id": "alt-ruby18",
        "name": "alt-ruby 18",
        "route": "alt-ruby18",
        "versions": {}
      },
      {
        "id": "alt-ruby19",
        "name": "alt-ruby 19",
        "route": "alt-ruby19",
        "versions": {}
      },
      {
        "id": "alt-ruby20",
        "name": "alt-ruby 20",
        "route": "alt-ruby20",
        "versions": {}
      },
      {
        "id": "alt-ruby21",
        "name": "alt-ruby 21",
        "route": "alt-ruby21",
        "versions": {}
      },
      {
        "id": "alt-ruby22",
        "name": "alt-ruby 22",
        "route": "alt-ruby22",
        "versions": {}
      },
      {
        "id": "alt-ruby23",
        "name": "alt-ruby 23",
        "route": "alt-ruby23",
        "versions": {}
      },
      {
        "id": "alt-ruby24",
        "name": "alt-ruby 24",
        "route": "alt-ruby24",
        "versions": {}
      },
      {
        "id": "alt-ruby25",
        "name": "alt-ruby 25",
        "route": "alt-ruby25",
        "versions": {}
      },
      {
        "id": "alt-ruby26",
        "name": "alt-ruby 26",
        "route": "alt-ruby26",
        "versions": {}
      },
      {
        "id": "alt-ruby27",
        "name": "alt-ruby 27",
        "route": "alt-ruby27",
        "versions": {}
      },
      {
        "id": "alt-ruby30",
        "name": "alt-ruby 30",
        "route": "alt-ruby30",
        "versions": {}
      },
      {
        "id": "alt-ruby31",
        "name": "alt-ruby 31",
        "route": "alt-ruby31",
        "versions": {}
      },
      {
        "id": "alt-ruby32",
        "name": "alt-ruby 32",
        "route": "alt-ruby32",
        "versions": {}
      },
      {
        "id": "alt-ruby33",
        "name": "alt-ruby 33",
        "route": "alt-ruby33",
        "versions": {}
      }
    ]
  },
  {
    "id": "EasyApache Components",
    "name": "EasyApache Components",
    "children": [
      {
        "id": "ea-apache24",
        "name": "ea-apache24",
        "route": "ea-apache24",
        "versions": {}
      },
      {
        "id": "ea-apache24-mod_hostinglimits",
        "name": "ea-apache24-mod_hostinglimits",
        "route": "ea-apache24-mod_hostinglimits",
        "versions": {}
      },
      {
        "id": "ea-apache24-mod_suphp",
        "name": "ea-apache24-mod_suphp",
        "route": "ea-apache24-mod_suphp",
        "versions": {}
      },
      {
        "id": "ea-apache24-config",
        "name": "ea-apache24-config",
        "route": "ea-apache24-config",
        "versions": {}
      },
      {
        "id": "ea-nginx",
        "name": "ea-nginx",
        "route": "ea-nginx",
        "versions": {}
      },
      {
        "id": "ea-apache24-mod_lsapi",
        "name": "ea-apache24-mod_lsapi",
        "route": "ea-apache24-mod_lsapi",
        "versions": {}
      },
      {
        "id": "ea-openssl11",
        "name": "ea-openssl11",
        "route": "ea-openssl11",
        "versions": {}
      },
      {
        "id": "ea-php",
        "name": "ea-PHP Versions",
        "children": [
          {
            "id": "ea-php5",
            "name": "ea-php 5",
            "children": [
              {
                "id": "ea-php51",
                "name": "ea-php 5.1",
                "route": "ea-php51",
                "versions": {}
              },
              {
                "id": "ea-php52",
                "name": "ea-php 5.2",
                "route": "ea-php52",
                "versions": {}
              },
              {
                "id": "ea-php53",
                "name": "ea-php 5.3",
                "route": "ea-php53",
                "versions": {}
              },
              {
                "id": "ea-php54",
                "name": "ea-php 5.4",
                "route": "ea-php54",
                "versions": {}
              },
              {
                "id": "ea-php55",
                "name": "ea-php 5.5",
                "route": "ea-php55",
                "versions": {}
              },
              {
                "id": "ea-php56",
                "name": "ea-php 5.6",
                "route": "ea-php56",
                "versions": {}
              }
            ]
          },
          {
            "id": "ea-php7",
            "name": "ea-php 7",
            "children": [
              {
                "id": "ea-php70",
                "name": "ea-php 7.0",
                "route": "ea-php70",
                "versions": {}
              },
              {
                "id": "ea-php71",
                "name": "ea-php 7.1",
                "route": "ea-php71",
                "versions": {}
              },
              {
                "id": "ea-php72",
                "name": "ea-php 7.2",
                "route": "ea-php72",
                "versions": {}
              },
              {
                "id": "ea-php73",
                "name": "ea-php 7.3",
                "route": "ea-php73",
                "versions": {}
              },
              {
                "id": "ea-php74",
                "name": "ea-php 7.4",
                "route": "ea-php74",
                "versions": {}
              }
            ]
          },
          {
            "id": "ea-php8",
            "name": "ea-php 8",
            "children": [
              {
                "id": "ea-php80",
                "name": "ea-php 8.0",
                "route": "ea-php80",
                "versions": {}
              },
              {
                "id": "ea-php81",
                "name": "ea-php 8.1",
                "route": "ea-php81",
                "versions": {}
              },
              {
                "id": "ea-php82",
                "name": "ea-php 8.2",
                "route": "ea-php82",
                "versions": {}
              },
              {
                "id": "ea-php83",
                "name": "ea-php 8.3",
                "route": "ea-php83",
                "versions": {}
              },
              {
                "id": "ea-php84",
                "name": "ea-php 8.4",
                "route": "ea-php84",
                "versions": {}
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "id": "DB Governor Packages",
    "name": "DB Governor Packages",
    "children": [
      {
        "id": "governor-mysql",
        "name": "governor-mysql",
        "route": "governor-mysql",
        "versions": {}
      },
      {
        "id": "cl-MariaDB",
        "name": "Compatible MariaDB",
        "children": [
          {
            "id": "cl-MariaDB55",
            "name": "MariaDB5.5",
            "route": "cl-MariaDB55",
            "versions": {}
          },
          {
            "id": "cl-MariaDB100",
            "name": "MariaDB10.0",
            "route": "cl-MariaDB100",
            "versions": {}
          },
          {
            "id": "cl-MariaDB101",
            "name": "MariaDB10.1",
            "route": "cl-MariaDB101",
            "versions": {}
          },
          {
            "id": "cl-MariaDB102",
            "name": "MariaDB10.2",
            "route": "cl-MariaDB102",
            "versions": {}
          },
          {
            "id": "cl-MariaDB103",
            "name": "MariaDB10.3",
            "route": "cl-MariaDB103",
            "versions": {}
          },
          {
            "id": "cl-MariaDB104",
            "name": "MariaDB10.4",
            "route": "cl-MariaDB104",
            "versions": {}
          },
          {
            "id": "cl-MariaDB105",
            "name": "MariaDB10.5",
            "route": "cl-MariaDB105",
            "versions": {}
          },
          {
            "id": "cl-MariaDB106",
            "name": "MariaDB10.6",
            "route": "cl-MariaDB106",
            "versions": {}
          },
          {
            "id": "cl-MariaDB1011",
            "name": "MariaDB10.11",
            "route": "cl-MariaDB1011",
            "versions": {}
          },
          {
            "id": "cl-MariaDB114",
            "name": "MariaDB11.4",
            "route": "cl-MariaDB1104",
            "versions": {}
          }
        ]
      },
      {
        "id": "cl-MySQL",
        "name": "Compatible MySQL",
        "children": [
          {
            "id": "cl-MySQL55",
            "name": "MySQL 5.5",
            "route": "cl-MySQL55",
            "versions": {}
          },
          {
            "id": "cl-MySQL56",
            "name": "MySQL 5.6",
            "route": "cl-MySQL56",
            "versions": {}
          },
          {
            "id": "cl-MySQL57",
            "name": "MySQL 5.7",
            "route": "cl-MySQL57",
            "versions": {}
          },
          {
            "id": "cl-MySQL80",
            "name": "MySQL 8.0",
            "route": "cl-MySQL80",
            "versions": {}
          }
        ]
      }
    ]
  },
  {
    "id": "mod_lsapi Versions",
    "name": "mod_lsapi Versions",
    "children": [
      {
        "id": "mod_lsapi",
        "name": "mod_lsapi",
        "route": "mod_lsapi",
        "versions": {}
      },
      {
        "id": "liblsapi",
        "name": "liblsapi",
        "route": "liblsapi",
        "versions": {}
      }
    ]
  },
  {
    "id": "CloudLinux OS Shared Pro",
    "name": "CloudLinux OS Shared Pro",
    "children": [
      {
        "id": "lvemanager-xray",
        "name": "lvemanager-xray",
        "route": "lvemanager-xray",
        "versions": {}
      },
      {
        "id": "cl-end-server-tools",
        "name": "cl-end-server-tools",
        "route": "cl-end-server-tools",
        "versions": {}
      },
      {
        "id": "alt-php-xray",
        "name": "alt-php-xray",
        "route": "alt-php-xray",
        "versions": {}
      },
      {
        "id": "alt-php-ssa",
        "name": "alt-php-ssa",
        "route": "alt-php-ssa",
        "versions": {}
      },
      {
        "id": "accelerate-wp",
        "name": "accelerate-wp",
        "route": "accelerate-wp",
        "versions": {}
      },
      {
        "id": "cloudlinux-awp-plugin",
        "name": "cloudlinux-awp-plugin",
        "route": "cloudlinux-awp-plugin",
        "versions": {}
      },
      {
        "id": "cloudlinux-site-optimization-module",
        "name": "cloudlinux-site-optimization-module",
        "route": "cloudlinux-site-optimization-module",
        "versions": {}
      }
    ]
  },
  {
    "id": "Other Components",
    "name": "Other Components",
    "children": [
      {
        "id": "cloudlinux-container",
        "name": "CloudLinux Container",
        "route": "cloudlinux-container",
        "versions": {}
      },
      {
        "id": "grub2",
        "name": "grub2",
        "route": "grub2",
        "versions": {}
      },
      {
        id: "cldeploy",
        name: "cldeploy",
        route: "cldeploy",
        "versions": {}
      },
      {
        id: "ubuntu2cloudlinux",
        name: "ubuntu2cloudlinux",
        route: "ubuntu2cloudlinux",
        "versions": {}
      }
    ]
  }
]

const clElevate: Array<object> = [
  {
    "id": "CloudLinux ELevate",
    "name": "CloudLinux ELevate",
    "children": [
      {
        id: "leapp-repository",
        name: "leapp-repository",
        route: "leapp-repository",
        "versions": {}
      },
      {
        id: "leapp-data-cloudlinux",
        name: "leapp-data-cloudlinux",
        route: "leapp-data-cloudlinux",
        "versions": {}
      },
    ]
  }
]

const CVEConfig: Array<object> = [
  {
    id: "cve",
    name: "CVE Dashboard (CL8)",
    route: "cve",
  },
]

const fullConfig = [
  ...CVEConfig,
  ...packages,
  ...clElevate
]
export default fullConfig;
